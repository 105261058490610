<template>
  <div class="gas-page">
    <div class="content-container">
      <h1>GÍA XĂNG DẦU HÔM NAY</h1>
      <div v-if="gasPrices && gasPrices.length">
        <table class="modern-table">
          <thead>
            <tr>
              <th class="column-id">#</th>
              <th>Ngày hiệu lực</th>
              <th>Ngày hết hiệu lực</th>
              <th>Sản phẩm</th>
              <th>Giá</th>
              <th class="column-uid">Vùng</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(price, index) in paginatedItems" :key="index" :class="{'odd-row': (currentPage - 1) * pageSize + index % pageSize % 2 === 0, 'even-row': (currentPage - 1) * pageSize + index % pageSize % 2 !== 0}">
              <td>{{ (currentPage - 1) * pageSize + index + 1 }}</td>
              <td>{{ price['Ngày bắt đầu hiệu lực']}}</td>
              <td>{{ price['Ngày hết hiệu lực']}}</td>
              <td>{{ price['Sản phẩm']}}</td>
              <td>{{ price['Giá']}}</td>
              <td>{{ price['Vùng']}}</td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
          <span>Page {{ currentPage }} of {{ totalPages }}</span>
          <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
        </div>
      </div>
      <div v-else>
        Đang tải giá xăng dầu...
      </div>
    </div>
    <footer class="footer">
      <p>copyright &copy; HKOIL</p>
    </footer>
  </div>
</template>
<script>
import { ref, computed, onMounted } from 'vue'; // Import ref, computed và onMounted
import axios from 'axios';
import { useGasStore } from '@/stores/gasStore';

export default {
  setup() {
    const gasStore = useGasStore(); // Khởi tạo gasStore
    const gasPrices = ref([]); // Sử dụng ref để lưu trữ gasPrices
    const currentPage = ref(1);
    const pageSize = 20;

    const totalPages = computed(() => {
      return Math.ceil(gasPrices.value.length / pageSize);
    });

    const paginatedItems = computed(() => {
      const start = (currentPage.value - 1) * pageSize;
      const end = start + pageSize;
      return gasPrices.value.slice(start, end);
    });

    const fetchGasPrices = async () => {
      try {
        const gasUrl = gasStore.gasUrl; // Truy cập gasUrl từ store
        const response = await axios.get(gasUrl);
        gasPrices.value = response.data; // Lưu kết quả vào gasPrices
      } catch (error) {
        if (error.code === 'ERR_NETWORK') {
          try {
            const gasUrl2 = gasStore.transURL; // URL API thứ 2
            const response = await axios.get(gasUrl2);
            gasPrices.value = response.data; // Lưu kết quả từ gasUrl2 vào gasPrices
          } catch (error2) {
            console.error('Error fetching gas prices from gasUrl2:', error2);
          }
        } else {
          console.error('Error fetching gas prices:', error);
        }
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

    onMounted(() => {
      fetchGasPrices(); // Gọi fetchGasPrices khi component được gắn vào DOM
    });

    return {
      gasPrices,
      currentPage,
      pageSize,
      totalPages,
      paginatedItems,
      fetchGasPrices,
      prevPage,
      nextPage,
    };
  },
};
</script>

<style scoped>
.gas-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the page takes up at least the full viewport height */
  padding: 20px;
  background-color: #f4f4f4;
}

.content-container {
  flex: 1; /* Fill the available space and push footer to the bottom */
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  overflow-x: auto; /* Enable horizontal scrolling if needed */
}

h1 {
  margin-top: 0;
  color: #333;
  font-size: 24px;
}

.modern-table {
  width: 100%;
  border-collapse: collapse;
}

.modern-table th,
.modern-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  word-break: break-word;
}

.modern-table th.column-id,
.modern-table td.column-id {
  min-width: 5%; /* Specific width for # column */
}

.modern-table th.column-uid,
.modern-table td.column-uid {
  min-width: 28px; /* Specific width for Uid column */
}

.modern-table th {
  background: linear-gradient(135deg, #1e3c72, #2a5298);
  color: white;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.modern-table tr {
  transition: background-color 0.3s ease;
}

.modern-table tr:hover {
  background-color: #f1f1f1;
}

.odd-row {
  background-color: #f9f9f9;
}

.even-row {
  background-color: #ffffff;
}

.pagination {
  margin-top: 20px;
  text-align: center;
}

.pagination button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #45a049;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination span {
  margin: 0 10px;
  font-weight: bold;
}

.footer {
  padding: 20px;
  background-color: #333;
  color: white;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .gas-page {
    padding: 10px;
  }

  .content-container {
    padding: 15px;
  }

  h1 {
    font-size: 20px;
  }

  .modern-table th,
  .modern-table td {
    padding: 8px 10px;
  }

  .pagination button {
    padding: 8px 15px;
    font-size: 14px;
  }

  .pagination span {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .content-container {
    padding: 10px;
  }

  h1 {
    font-size: 18px;
  }

  .modern-table th,
  .modern-table td {
    padding: 6px 8px;
  }

  .pagination button {
    padding: 6px 12px;
    font-size: 12px;
  }

  .pagination span {
    font-size: 12px;
  }
}
</style>
