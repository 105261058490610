<template>
  <div class="forgot-password-container">
    <h2>Quên Mật Khẩu</h2>
    <form @submit.prevent="forgotPassword">
      <div class="form-group">
        <label for="username">Username</label>
        <input
          type="text"
          id="username"
          v-model="userData.username"
          @blur="validateUsername"
          required
        />
        <span v-if="errors.username">{{ errors.username }}</span>
      </div>
      <div class="form-group">
        <label for="email">Email</label>
        <input
          type="email"
          id="email"
          v-model="userData.email"
          @blur="validateEmail"
          required
        />
        <span v-if="errors.email">{{ errors.email }}</span>
      </div>
      <button type="submit" class="submit-button">Gửi Yêu Cầu</button>
    </form>
    <div v-if="responseMessage" class="response-message">{{ responseMessage }}</div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';
import { useGasStore } from '@/stores/gasStore';

const userData = ref({
  username: '',
  email: ''
});
const errors = ref({});
const responseMessage = ref('');
const userStore = useGasStore();
const fUrl = userStore.authURL  + 'auth/register';

// Validate Username (tối thiểu 4 ký tự)
const validateUsername = () => {
  if (userData.value.username.length < 4) {
    errors.value.username = 'Username phải có ít nhất 4 ký tự';
  } else {
    errors.value.username = '';
  }
};

// Validate Email (theo định dạng hợp lệ)
const validateEmail = () => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailPattern.test(userData.value.email)) {
    errors.value.email = 'Email không hợp lệ';
  } else {
    errors.value.email = '';
  }
};

// Submit Form (Gọi API quên mật khẩu)
const forgotPassword = async () => {
  validateUsername();
  validateEmail();

  if (Object.values(errors.value).every((err) => err === '')) {
    try {
      const response = await axios.post(fUrl, {
        username: userData.value.username,
        email: userData.value.email
      });

      if (response.status === 201) {
        responseMessage.value = 'Yêu cầu đã được gửi thành công!';
      } else {
        responseMessage.value = response.data.message || 'Đã xảy ra lỗi khi gửi yêu cầu';
      }
    } catch (error) {
      responseMessage.value = error.response?.data?.message || 'Đã xảy ra lỗi khi gửi yêu cầu';
    }
  }
};
</script>

<style scoped>
.forgot-password-container {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

span {
  color: red;
  font-size: 12px;
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #45a049;
}

.response-message {
  margin-top: 20px;
  text-align: center;
  color: red;
}
</style>
