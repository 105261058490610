<template>
  <div class="temp-link-container">
    <h2>THẺ THÀNH VIÊN HKOIL</h2>
    <div v-if="isExpired">
      <p class="expired-message">Link đã hết hạn. Vui lòng tạo link mới.</p>
    </div>
    <div v-else-if="loading">Đang tải thông tin...</div>
    <div v-else-if="memberCardInfo" class="member-card" :class="`tier-${memberCardInfo.tier.toLowerCase()}`">
      <div class="card-content">
        <div class="card-info">
          <h3>THẺ THÀNH VIÊN HKOIL {{ memberCardInfo.tier }}</h3>
          <p><strong>Email:</strong> {{ memberCardInfo.email }}</p>
          <p><strong>Ngày tham gia:</strong> {{ new Date(memberCardInfo.created_at).toLocaleDateString() }}</p>

          <!-- Số điểm tích lũy với hiệu ứng che thông tin -->
          <p><strong>Số điểm tích lũy: </strong>
            <span v-if="showPoints" class="highlight">{{ memberCardInfo.points }}</span>
            <span class="highlight" v-else>{{ maskedPoints }}</span>
            <button @click="toggleShowPoints" class="icon-button">
              <i :class="showPoints ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
            </button>
          </p>

          <!-- Thông tin thẻ -->
          <p v-if="memberCardInfo.tier === 'Silver'">
            <strong>Thẻ Bạc:</strong> Sử dụng tối đa <span class="highlight">10 điểm</span> tích lũy cho mỗi lần mua xăng dầu, cùng nhiều ưu đãi đặc biệt vào ngày lễ.
          </p>
          <p v-if="memberCardInfo.tier === 'Gold'">
            <strong>Thẻ Vàng:</strong> Sử dụng tối đa <span class="highlight">30 điểm</span> tích lũy cho mỗi lần mua xăng dầu, ưu tiên phục vụ vào giờ cao điểm, và quà tặng định kỳ hàng quý.
          </p>
          <p v-if="memberCardInfo.tier === 'Diamond'">
            <strong>Thẻ Kim Cương:</strong> Sử dụng tối đa <span class="highlight">100 điểm</span> tích lũy cho mỗi lần mua xăng dầu, ưu tiên phục vụ mọi lúc, hỗ trợ thanh toán nhanh và quyền lợi VIP trong các chương trình khuyến mãi đặc biệt.
          </p>
        </div>
        <!-- QR Code image -->
        <div class="qr-code-container">
          <img :src="qrCodeUrl" alt="QR Code" class="qr-code" />
        </div>
      </div>
      <div class="tier-label">{{ memberCardInfo.tier }}</div>
    </div>
    <div v-else>
      <p class="error-message">Không tìm thấy thông tin thẻ.</p>
    </div>

      <!-- Countdown timer -->
      <div v-if="!isExpired && memberCardInfo"  class="countdown-timer"><i class="fa-regular fa-hourglass-half"></i> {{ countdownDisplay }}</div>
      <!-- Nút tải xuống -->
      <button v-if="!isExpired && memberCardInfo" @click="downloadCard" class="download-button"><i class="fa-regular fa-circle-down"></i> Tải xuống thẻ</button>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import api from '@/api/axios';
import { useRoute } from 'vue-router';
import QRCode from 'qrcode';
import { useGasStore } from '@/stores/gasStore';
import html2canvas from 'html2canvas'; // Thêm import này

const route = useRoute();
const ePhone = route.query.k4NjczODQxMg;
const eLicensePlate = route.query.adqdDWQFB;
const eCreatedAt = route.query.cASDQVvas;
const gasStore = useGasStore();

const phone = ePhone ? atob(ePhone) : null;
const licensePlate = eLicensePlate ? atob(eLicensePlate) : null;
const createdAt = eCreatedAt ? parseInt(atob(eCreatedAt)) * 1000 : null;
const cIkancwERV = gasStore.cIkancwERV;
const cSAFQFsadQ = gasStore.cSAFQFsadQ;

const loading = ref(true);
const memberCardInfo = ref(null);
const isExpired = ref(false);
const qrCodeUrl = ref('');
const countdownDisplay = ref('');
const showPoints = ref(false);

let countdownInterval = null;

const checkExpiration = () => {
  if (!createdAt) {
    isExpired.value = true;
    return;
  }
  const now = Date.now();
  const diffInMinutes = (now - createdAt) / 1000 / 60;
  isExpired.value = diffInMinutes > 3;
};

const startCountdown = () => {
  countdownInterval = setInterval(() => {
    const now = Date.now();
    const remainingTime = createdAt + 3 * 60 * 1000 - now;

    if (remainingTime <= 0) {
      clearInterval(countdownInterval);
      isExpired.value = true;
    } else {
      const minutes = Math.floor(remainingTime / 60000);
      const seconds = Math.floor((remainingTime % 60000) / 1000);
      countdownDisplay.value = `${minutes} phút ${seconds} giây`;
    }
  }, 1000);
};

const fetchMemberCardInfo = async () => {
  loading.value = true;
  try {
    const response = await api.post('/auth/mcard', {
      phone,
      licensePlate,
      cIkancwERV,
      cSAFQFsadQ
    });
    memberCardInfo.value = response.data;
    qrCodeUrl.value = await QRCode.toDataURL(response.data.email);
  } catch (error) {
    console.error('Error fetching member card:', error);
  } finally {
    loading.value = false;
  }
};

const toggleShowPoints = () => {
  showPoints.value = !showPoints.value;
};

// Computed property để tạo số lượng ký tự * tương ứng với điểm
const maskedPoints = computed(() => {
  const points = memberCardInfo.value ? memberCardInfo.value.points : 0;
  const stars = points.toString().length; // Số lượng ký tự '*' bằng với số chữ số của điểm
  return '*'.repeat(stars);
});

// Hàm tải xuống thẻ thành viên
const downloadCard = async () => {
  const cardElement = document.querySelector('.member-card'); // Chọn phần tử cần tải xuống
  if (!cardElement) return;

  const canvas = await html2canvas(cardElement); // Tạo canvas từ phần tử
  const imgData = canvas.toDataURL('image/png'); // Chuyển đổi canvas thành hình ảnh

  const link = document.createElement('a'); // Tạo một liên kết tải xuống
  const timestamp = new Date().toISOString().replace(/[:.]/g, '-'); // Chuyển đổi timestamp thành chuỗi hợp lệ cho tên file
  const filename = `hkoil_member_card_${timestamp}.png`; // Tạo tên file
  link.href = imgData; // Đặt href cho liên kết
  link.download = filename; // Đặt tên file tải xuống
  link.click(); // Thực hiện nhấp chuột trên liên kết
};

onMounted(() => {
  checkExpiration();
  if (!isExpired.value) {
    fetchMemberCardInfo();
    startCountdown();
  }
});

onUnmounted(() => {
  if (countdownInterval) clearInterval(countdownInterval);
});
</script>

<style scoped>
.temp-link-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #379d16;
}

.expired-message, .error-message {
  color: red;
  font-weight: bold;
}

.member-card {
  position: relative;
  padding: 20px;
  border-radius: 5px;
  background-color: #f0f4f8;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.member-card.tier-silver {
  background-color: #f3f3f3;
}

.member-card.tier-gold {
  background-color: #ffd700;
}

.member-card.tier-diamond {
  background-color: #b9f2ff;
}

.card-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-info {
  flex: 1;
  text-align: left;
}

.highlight {
  font-weight: bold;
}

.qr-code-container {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.qr-code {
  width: 100px;
  height: 100px;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 10px;
  background-color: #fff;
}

.tier-label {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
}

.tier-silver .tier-label {
  background-color: #a9a9a9;
}

.tier-gold .tier-label {
  background-color: #d4af37;
}

.tier-diamond .tier-label {
  background-color: #1e90ff;
}

.tier-silver .highlight {
  color: #363131bf;
}
.tier-gold .highlight {
  color: #ff4500;
}
.tier-diamond .highlight {
  color: #1e90ff;
}
.countdown-timer {
  margin-top: 10px;
  font-weight: bold;
  color: #ff4500;
}

.download-button {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 1rem;
  background: linear-gradient(90deg, #4e54c8, #8f94fb); /* Gradient màu xanh dương và tím */
  color: white;
  border: none;
  border-radius: 30px; /* Thay đổi góc bo để mềm mại hơn */
  cursor: pointer;
  transition: background 0.3s, transform 0.3s; /* Thêm hiệu ứng chuyển động */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Thêm bóng cho nút */
}

.download-button:hover {
  background: linear-gradient(90deg, #8f94fb, #4e54c8); /* Đảo ngược gradient khi hover */
  transform: scale(1.05); /* Tăng kích thước nhẹ khi hover */
}


.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #379d16;
}
</style>
