<template>
  <div class="logout-container">
    <h2 class="logout-title">THÔNG TIN NGƯỜI DÙNG</h2>
    <div v-if="loading">Loading...</div>
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>

    <div v-if="userInfo" class="member-card" :class="getCardTier(userInfo.created_at)">
      <div class="user-info">
        <p><strong>Email:</strong> {{ userInfo.email }}</p>
        <p><strong>Ngày tham gia:</strong> {{ userInfo.created_at }}</p>
      </div>
      <div class="qr-code">
        <vue-qrcode
          :value="generateQRCodeContent(userInfo.email, userInfo.created_at)"
          :size="100"
        ></vue-qrcode>
      </div>
      <div class="card-tier" :class="getCardTier(userInfo.created_at)">
        {{ getCardTier(userInfo.created_at) }}
      </div>
    </div>
    <div class="member-benefits">
      <h3>Giới thiệu các hạng thẻ thành viên và quyền lợi ưu đãi</h3>
      <p>
        Chúng tôi cung cấp ba hạng thẻ thành viên: <strong>Bạc</strong>, <strong>Vàng</strong>, và <strong>Kim Cương</strong>,
        dành cho khách hàng thân thiết trong lĩnh vực bán lẻ xăng dầu. Khi sở hữu thẻ, bạn sẽ được hưởng những ưu đãi độc quyền sau:
      </p>
      <ul>
        <li>
          <strong>Thẻ Bạc:</strong> Sử dụng tối đa <span class="highlight">10 điểm</span> tích lũy cho mỗi lần mua xăng dầu,
          cùng nhiều ưu đãi đặc biệt vào ngày lễ.
        </li>
        <li>
          <strong>Thẻ Vàng:</strong> Sử dụng tối đa <span class="highlight">30 điểm</span> tích lũy cho mỗi lần mua xăng dầu,
          ưu tiên phục vụ vào giờ cao điểm, và quà tặng định kỳ hàng quý.
        </li>
        <li>
          <strong>Thẻ Kim Cương:</strong> Sử dụng tối đa <span class="highlight">100 điểm</span> tích lũy cho mỗi lần mua xăng dầu,
          ưu tiên phục vụ mọi lúc, hỗ trợ thanh toán nhanh và quyền lợi VIP trong các chương trình khuyến mãi đặc biệt.
        </li>
      </ul>
    </div>

    <div class="card-samples">
  <div class="sample-card Silver">
    <h3>Thẻ Bạc</h3>
    <vue-qrcode :value="'Silver Sample'" :size="70"></vue-qrcode>
  </div>
  <div class="sample-card Gold">
    <h3>Thẻ Vàng</h3>
    <vue-qrcode :value="'Gold Sample'" :size="70"></vue-qrcode>
  </div>
  <div class="sample-card Diamond">
    <h3>Thẻ Kim Cương</h3>
    <vue-qrcode :value="'Diamond Sample'" :size="70"></vue-qrcode>
  </div>
</div>

    <button @click="logout" class="logout-button">Đăng Xuất</button>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import api from '@/api/axios';
import { useAuthService } from '@/services/authService';
import VueQrcode from '@chenfengyuan/vue-qrcode';

const userInfo = ref(null);
const loading = ref(true);
const errorMessage = ref('');

const { logout } = useAuthService();

const fetchUserProfile = async () => {
  try {
    const response = await api.get('/user/profile');
    userInfo.value = response.data;
  } catch (error) {
    errorMessage.value = 'Failed to load profile information.';
    console.error('Profile fetch error:', error);
    await logout();
  } finally {
    loading.value = false;
  }
};

const generateQRCodeContent = (email, createdAt) => {
  return `Member Info:\nEmail: ${email}\nCreated At: ${createdAt}`;
};

const getCardTier = (createdAt) => {
  return createdAt.includes('2022') ? 'Silver' : createdAt.includes('2023') ? 'Diamond' : 'Gold';
};

onMounted(fetchUserProfile);
</script>

<style scoped>
.logout-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  text-align: center;
}

.logout-title {
  margin-bottom: 20px;
  font-size: 2rem;
  background: linear-gradient(90deg, #379d16, #feb47b);
  -webkit-background-clip: text;
  color: transparent;
  font-weight: bold;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 15px;
}

.logout-button {
  padding: 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  max-width: 200px;
  font-size: 1rem;
  margin-top: 20px;
}

.logout-button:hover {
  background-color: #c82333;
}

.member-card {
  position: relative;
  max-width: 400px;
  padding: 20px;
  margin: auto;
  border-radius: 12px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  background: linear-gradient(135deg, #ffffff, #f0f0f0);
  text-align: left;
}

/* Thêm các lớp màu sắc cho member-card theo cấp độ */
.member-card.Silver {
  background: linear-gradient(135deg, #e0e0e0, #c0c0c0);
}
.member-card.Gold {
  background: linear-gradient(135deg, #ffd700, #ffec8b);
}
.member-card.Diamond {
  background: linear-gradient(135deg, #b0e0e6, #00bfff);
}

.user-info {
  font-size: 1rem;
  margin-bottom: 20px;
}

.qr-code {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0; /* Loại bỏ padding để QR code không có viền trắng */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  border-radius: 8px; /* Có thể giữ hoặc bỏ tùy theo ý muốn */
}


.qrcode-image {
  padding: 0;
  margin: 0;
  width: 100px;
  height: 100px;
}
.member-benefits {
  margin: 20px auto;
  padding: 20px;
  max-width: 600px; /* Giới hạn chiều rộng tối đa */
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  transition: all 0.3s ease; /* Hiệu ứng chuyển tiếp cho chiều rộng */
}

.member-benefits h3 {
  text-align: center;
  font-size: 1.75rem;
  margin-bottom: 15px;
  color: #333;
  font-weight: bold;
}

.member-benefits p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 15px;
  text-align: justify;
  line-height: 1.5;
}

.member-benefits ul {
  list-style-type: none;
  padding: 0;
  font-size: 1rem;
}

.member-benefits li {
  margin-bottom: 15px;
  color: #444;
  text-align: justify;
  position: relative;
  padding-left: 20px;
}

.member-benefits li::before {
  content: '✔';
  position: absolute;
  left: 0;
  color: #28a745; /* Màu xanh cho biểu tượng kiểm tra */
}

.member-benefits .highlight {
  font-weight: bold;
  color: #d9534f; /* Màu đỏ cho điểm tích lũy nổi bật */
}

/* Responsive styles */
@media (max-width: 768px) {
  .member-benefits {
    padding: 15px;
    margin: 10px;
    max-width: 100%; /* Chiều rộng tối đa 100% trên màn hình nhỏ */
  }

  .member-benefits h3 {
    font-size: 1.5rem; /* Giảm kích thước chữ cho tiêu đề */
  }

  .member-benefits p,
  .member-benefits li {
    font-size: 0.9rem; /* Giảm kích thước chữ cho đoạn văn và danh sách */
  }
   .sample-card {
    flex: 1 1 45%; /* 2 cards per row on tablet */
  }
}

@media (max-width: 480px) {
  .member-benefits {
    padding: 10px;
  }

  .member-benefits h3 {
    font-size: 1.25rem; /* Kích thước chữ tiêu đề nhỏ hơn cho điện thoại */
  }

  .member-benefits p,
  .member-benefits li {
    font-size: 0.85rem; /* Kích thước chữ nhỏ hơn cho đoạn văn và danh sách */
  }
  .card-samples {
    flex-direction: column;
    gap: 15px;
  }
  
  .sample-card {
    flex: 1 1 100%;
    padding: 15px;
  }

  .sample-card h3 {
    font-size: 1.25rem;
  }
}
.card-tier {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 5px 10px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  border-radius: 8px;
}

.card-tier.Silver {
  background: #c0c0c0;
}
.card-tier.Gold {
  background: #ffd700;
}
.card-tier.Diamond {
  background: #00bfff;
}

.card-samples {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 20px 0;
}

.sample-card {
  width: 120px;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  color: #fff;
  transition: transform 0.3s;
}

.sample-card.Silver { background: #c0c0c0; }
.sample-card.Gold { background: #ffd700; }
.sample-card.Diamond { background: #00bfff; }

@media (max-width: 768px) {
  .card-samples { flex-direction: column; }
  .sample-card { width: 100%; }
}

/* Responsive styles */
@media (max-width: 600px) {
  .user-info, .qr-code {
    text-align: center;
  }
}
</style>
