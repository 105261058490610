<template>
  <div>
    <GasPriceChart :gasData="gasData" />
    <h1>Thông tin giá xăng</h1>
    <div v-if="gasData.length > 0">
      <div v-for="(item, index) in gasData" :key="index">
        <p>Ngày bắt đầu hiệu lực: {{ item['Ngày bắt đầu hiệu lực'] }}</p>
        <p>Sản phẩm: {{ item['Sản phẩm'] }}</p>
        <p>Giá: {{ item['Giá'] }} VND</p>
        <p>Vùng: {{ item['Vùng'] }}</p>
        <hr /> <!-- Dùng để tạo đường ngăn cách giữa các bản ghi -->
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import GasPriceChart from './GasPriceChart.vue'; // Import component biểu đồ
import { useGasStore } from '@/stores/gasStore';

export default {
  components: {
    GasPriceChart,
  },
  setup() {
    const gasData = ref([]); // Sử dụng ref để lưu trữ gasData
    const gasStore = useGasStore(); // Khởi tạo gasStore
    const cacheKey = 'gasDataCache'; // Khóa lưu trữ cache
    const expirationKey = 'gasDataExpiration'; // Khóa lưu trữ thời gian hết hạn cache

    const fetchGasData = async () => {
      try {
        const response = await axios.get(gasStore.gasUrl); // Gọi API đầu tiên
        gasData.value = response.data; // Gán dữ liệu JSON vào gasData
        // Cập nhật cache
        localStorage.setItem(cacheKey, JSON.stringify(response.data));
        // Cập nhật thời gian hết hạn (expiration là 1 ngày)
        const now = new Date();
        const expirationTime = new Date(now.getTime() + 24 * 60 * 60 * 1000); // Hết hạn sau 1 ngày
        localStorage.setItem(expirationKey, expirationTime.getTime());
      } catch (error) {
        // Kiểm tra mã lỗi trả về
        if (error.code === 'ERR_NETWORK') {
          try {
            const fallbackResponse = await axios.get(gasStore.transURL); // Gọi API dự phòng
            gasData.value = fallbackResponse.data; // Gán dữ liệu JSON vào gasData
            // Cập nhật cache
            localStorage.setItem(cacheKey, JSON.stringify(fallbackResponse.data));
            // Cập nhật thời gian hết hạn
            const now = new Date();
            const expirationTime = new Date(now.getTime() + 24 * 60 * 60 * 1000); // Hết hạn sau 1 ngày
            localStorage.setItem(expirationKey, expirationTime.getTime());
          } catch (fallbackError) {
            console.error('Error fetching gas data from fallback URL:', fallbackError);
          }
        } else {
          console.error('Error fetching gas data:', error);
        }
      }
    };

    const loadGasData = () => {
      const cachedData = localStorage.getItem(cacheKey);
      const expirationTime = localStorage.getItem(expirationKey);

      if (cachedData && expirationTime) {
        const now = new Date().getTime();
        if (now < expirationTime) {
          // Nếu cache còn hiệu lực
          gasData.value = JSON.parse(cachedData);
        } else {
          // Nếu cache đã hết hiệu lực, gọi API
          fetchGasData();
        }
      } else {
        // Nếu không có cache, gọi API
        fetchGasData();
      }
    };

    onMounted(() => {
      loadGasData(); // Gọi hàm khi component được gắn vào DOM
    });

    return {
      gasData,
    };
  },
};
</script>
