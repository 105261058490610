import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/components/Home.vue';
import AboutPage from '@/components/About.vue';
import SystemList from '@/components/SystemList.vue'; // Import component
import GasPrice from '@/components/GasPrice.vue'; // Import component
import HelloWorld from '@/components/HelloWorld.vue'; // Import component
import TimeLine from '@/components/Timeline.vue'; // Import component
import UserLogin from '@/components/UserLogin.vue'; // Import component
import UserLogout from '@/components/UserLogout.vue'; // Import component
import UserTrans from '@/components/UserTrans.vue'; // Import component
import RegisterMember from '@/aupr/RegisterMember.vue'; // Import component
import forgotPassword from '@/aupr/forgotPassword.vue'; // Import component
import VSea from '@/components/VSea.vue'; // Import component
import TempLink from '@/components/TempLink.vue'; // Import component
import { useAuthStore } from '@/stores/authStore';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: AboutPage
  },
  {
    path: '/systems',
    name: 'SystemList',
    component: SystemList, // Sử dụng component đã import
    props: (route) => ({ systems: route.query.systems })
  },
  {
    path: '/gas-price',
    name: 'GasPrice',
    component: GasPrice
  },
  {
    path: '/timeline',
    name: 'TimeLine',
    component: TimeLine
  },
  {
      path: '/dang-nhap',
      name: 'UserLogin',
      component: UserLogin
  },
  {
      path: '/dang-ky',
      name: 'RegisterMember',
      component: RegisterMember
  },
  {
      path: '/forgot-password',
      name: 'forgotPassword',
      component: forgotPassword
  },
  {
      path: '/67225d8f-d584-8011-8f3f-b7ca2abe556c-abckfaf89hk',
      name: 'VSea',
      component: VSea
  },
  {
        path: '/t-mem',
        name: 'TempLink',
        component: TempLink,
        props: (route) => ({
          phone: route.query.phone,
          licensePlate: route.query.licensePlate,
          createdAt: route.query.createdAt,
        }),
  },
  {
      path: '/dang-nhap',
      name: 'UserLogin',
      component: UserLogin,
      beforeEnter: (to, from, next) => {
        const authStore = useAuthStore();
        if (authStore.isAuthenticated) {
          next('/'); // Redirect về trang chính nếu đã đăng nhập
        } else {
          next(); // Cho phép truy cập nếu chưa đăng nhập
        }
      },
   },
  {
      path: '/dang-xuat',
      name: 'Logout',
      component: UserLogout,
      beforeEnter: (to, from, next) => {
        const authStore = useAuthStore();
        if (!authStore.isAuthenticated) {
          next('/dang-nhap'); // Redirect về trang login nếu chưa đăng nhập
        } else {
          next(); // Cho phép truy cập nếu đã đăng nhập
        }
      },
  },
  {
        path: '/logout',
        name: 'UserTrans',
        component: UserTrans,
        beforeEnter: (to, from, next) => {
          const authStore = useAuthStore();
          if (!authStore.isAuthenticated) {
            next('/dang-nhap'); // Redirect về trang login nếu chưa đăng nhập
          } else {
            next(); // Cho phép truy cập nếu đã đăng nhập
          }
        },
   },
  {
    path: '/hello-world',
    name: 'HelloWorld',
    component: HelloWorld,
    props: (route) => ({ msg: route.query.msg || 'Welcome to Your Vue.js App' }) // Truyền msg nếu có
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
