<template>
  <div class="transaction-container">
    <h2 class="transaction-title">LỊCH SỬ GIAO DỊCH</h2>

    <div v-if="loading" class="loading">Đang tải dữ liệu...</div>
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>

    <div v-if="transactions.length > 0">
      <div class="search-container">
        <input
          v-model="searchTerm"
          type="text"
          placeholder="Tìm kiếm theo sản phẩm hoặc vùng..."
          class="search-input"
        />
        <div class="date-range">
          <label for="startDate">Từ:</label>
          <input
            v-model="startDate"
            type="date"
            id="startDate"
            class="date-input"
          />
          <label for="endDate">Đến:</label>
          <input
            v-model="endDate"
            type="date"
            id="endDate"
            class="date-input"
          />
        </div>
        <button class="clear-filter" @click="clearFilters">Xóa Bộ Lọc</button>
      </div>

      <div class="table-container">
        <table class="table">
          <thead>
            <tr>
              <th>Ngày Hiệu Lực</th>
              <th>Vùng</th>
              <th>Sản Phẩm</th>
              <th>Mã Giá</th>
              <th>Ngày Hết Hiệu Lực</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="transaction in filteredTransactions" :key="transaction.id">
              <td>{{ formatDate(transaction.ngayhieuluc) }}</td>
              <td>{{ transaction.Vung }}</td>
              <td>{{ transaction.sanpham }}</td>
              <td>{{ formatCurrency(transaction.Ma_gia) }}</td>
              <td>{{ formatDate(transaction.ngayhetHieuluc) }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="pagination">
        <button @click="prevPage" :disabled="currentPage === 1">Trang Trước</button>
        <span>Trang {{ currentPage }} / {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">Trang Tiếp</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import api from '@/api/axios'; // Import API đã cấu hình
import { useAuthService } from '@/services/authService'; // Import hàm logout từ authService

// Các biến và trạng thái
const transactions = ref([]);
const loading = ref(true);
const errorMessage = ref('');
const currentPage = ref(1);
const pageSize = ref(10); // Số bản ghi mỗi trang
const searchTerm = ref(''); // Tìm kiếm
const startDate = ref(''); // Ngày bắt đầu
const endDate = ref(''); // Ngày kết thúc
// Gọi hàm logout từ authService
const { logout } = useAuthService();

// Định dạng tiền tệ
const formatCurrency = (amount) => {
  return new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND'
  }).format(amount);
};

// Định dạng ngày
const formatDate = (dateString) => {
  const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' };
  const date = new Date(dateString);
  return date.toLocaleDateString('vi-VN', options);
};

// Gọi API lấy thông tin transactions
const fetchTransactions = async () => {
  try {
    const response = await api.get('/user/transactions');
    transactions.value = response.data;
  } catch (error) {
    errorMessage.value = 'Không thể tải dữ liệu giao dịch.';
    console.error('Transaction fetch error:', error);
    await logout(); // Nếu lỗi, thực hiện logout
  } finally {
    loading.value = false;
  }
};

// Tính toán tổng số trang
const totalPages = computed(() => {
  return Math.ceil(filteredTransactions.value.length / pageSize.value);
});

// Lấy dữ liệu phân trang và tìm kiếm
const filteredTransactions = computed(() => {
  return transactions.value.filter(transaction => {
    const ngayhieuluc = new Date(transaction.ngayhieuluc.split(' ')[0]); // Chỉ lấy phần ngày
    const isInRange = (!startDate.value || ngayhieuluc >= new Date(startDate.value)) &&
                      (!endDate.value || ngayhieuluc <= new Date(endDate.value));

    return (
      isInRange &&
      (transaction.sanpham.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
      transaction.Vung.toLowerCase().includes(searchTerm.value.toLowerCase()))
    );
  }).slice((currentPage.value - 1) * pageSize.value, currentPage.value * pageSize.value);
});

// Chuyển trang
const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value += 1;
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value -= 1;
  }
};

// Clear filters
const clearFilters = () => {
  searchTerm.value = '';
  startDate.value = '';
  endDate.value = '';
  currentPage.value = 1; // Reset về trang đầu tiên
};

// Gọi fetchTransactions khi component được mount
onMounted(fetchTransactions);
</script>

<style scoped>
.transaction-container {
  max-width: 900px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.transaction-title {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 2rem;
  background: linear-gradient(90deg, #379d16, #feb47b);
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  font-weight: bold;
  letter-spacing: 1px;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 15px;
}

.loading {
  text-align: center;
}

.search-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.search-input {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.date-range {
  display: flex;
  flex-direction: column; /* Stack date inputs vertically */
  margin-top: 10px;
}

.date-input {
  padding: 10px;
  width: 100%; /* Full width for date inputs */
  margin-bottom: 10px; /* Space between inputs */
  box-sizing: border-box;
}

.clear-filter {
  padding: 10px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.clear-filter:hover {
  background-color: #c0392b; /* Darker red on hover */
}

.table-container {
  overflow-x: auto; /* Cho phép cuộn khi màn hình nhỏ */
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.table th,
.table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

.table th {
  background-color: #4CAF50;
  color: white;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 10px 15px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .transaction-title {
    font-size: 1.5rem;
  }

  .table th, .table td {
    padding: 8px;
    font-size: 0.9rem;
  }

  .pagination button {
    padding: 5px 10px;
  }

  .search-container {
    flex-direction: column; /* Đảm bảo các phần tử xếp chồng */
  }

  .date-range {
    flex-direction: column; /* Đảm bảo các trường ngày xếp chồng */
  }

  .clear-filter {
    margin-top: 5px; /* Giảm khoảng cách cho nút */
  }
}
</style>
