// src/stores/gasStore.js
import { defineStore } from 'pinia';

export const useGasStore = defineStore('gas', {
  state: () => ({
    gasUrl: 'https://zchatbot.onrender.com/api/Gasdata', //http://localhost:3000/api/Gasdata
    authURL: 'http://localhost:3000/api/', // Thêm thông tin authURL
    cIkancwERV: 'f8344306f1367406d452300kancAL', // Thêm trường cID
    cSAFQFsadQ: '981f6cmcae0UuHK0yYhA55mIopGUXiYoU6bf8344306f1367406d4', // Thêm trường cSe
    transURL: 'https://zchatbot-yzdc.onrender.com/api/Gasdata',
  }),
  actions: {
    setGasUrl(url) {
      this.gasUrl = url;
    },
    clearGasUrl() {
      this.gasUrl = '';
    },
    setAuthURL(url) {
      this.authURL = url; // Thêm hành động để cập nhật authURL
    },
    clearAuthURL() {
      this.authURL = ''; // Thêm hành động để xóa authURL
    },
    setTransURL(url) {
      this.transURL = url; // Thêm hành động để cập nhật transURL
    },
    clearTransURL() {
      this.transURL = '';
    },
    setCIkancwERV(id) {
      this.cIkancwERV = id;
    },
    clearCIkancwERV() {
      this.cIkancwERV = '';
    },
    setCSAFQFsadQ(secret) {
      this.cSAFQFsadQ = secret;
    },
    clearCSAFQFsadQ() {
      this.cSAFQFsadQ = '';
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'gasStore', // Đổi khóa lưu trữ để bao gồm tất cả thông tin
        storage: localStorage, // Hoặc sessionStorage nếu cần
        paths: ['gasUrl', 'authURL', 'cID', 'cSe', 'transURL'], // Chỉ định các state cần lưu trữ
      },
    ],
  },
});
