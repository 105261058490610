<template>
  <div class="about-page">
    <div class="content-container">
      <h1>Về Chúng Tôi</h1>
      <p>
        Đây là trang về chúng tôi. Tại đây, bạn sẽ tìm thấy thông tin về lịch sử, sứ mệnh, và đội ngũ của chúng tôi. Chúng tôi cam kết cung cấp dịch vụ tốt nhất và tạo ra giá trị cho khách hàng của mình.
      </p>
      <!-- Tìm cửa hàng HKOIL với hiệu ứng -->
      <a class="store-link" href="https://google.com/maps/search/hkoil" target="_blank">Tìm cửa hàng HKOIL</a>
    </div>

    <div class="download-apps">
      <p>Tải ứng dụng của chúng tôi trên:</p>
      <div class="app-buttons">
        <a href="https://link.hkoil.org/hkapp" target="_blank">
          <img src="../assets/Google_Play.svg" alt="Google Play" class="app-logo" />
        </a>
        <a href="https://www.apple.com/app-store/" target="_blank">
          <img src="../assets/app_store.svg" alt="App Store" class="app-logo" />
        </a>
      </div>
    </div>
    <!-- Curved Timeline -->
    <div class="timeline-wrapper">
      <div class="timeline-container">
        <!-- Enhanced curved timeline -->
        <svg viewBox="0 0 800 800">
          <path d="M 50 50 C 200 200, 600 200, 750 750" stroke="#FFD700" stroke-width="5" fill="transparent" />
        </svg>

        <!-- Milestones and dates -->
        <div class="milestone milestone1">
          <div class="milestone-content">Ông Ngô Kiên Thuận và Bà Nguyễn Thị Kiều Trang thành lập cty</div>
        </div>
        <div class="milestone-time milestone-time1">2021</div>

        <div class="milestone milestone2">
          <div class="milestone-content">THƯƠNG NHÂN PHÂN PHỐI. Chính thức được cấp 29/03/2022</div>
        </div>
        <div class="milestone-time milestone-time2">2022</div>

        <div class="milestone milestone3">
          <div class="milestone-content">Triển khai dịch vụ 5*</div>
        </div>
        <div class="milestone-time milestone-time3">2023</div>

        <div class="milestone milestone4">
          <div class="milestone-content">Triển khai nhượng quyền HKOIL</div>
        </div>
        <div class="milestone-time milestone-time4">2024</div>

        <div class="milestone milestone5">
          <div class="milestone-content">Hoàn tất triển khai</div>
        </div>
        <div class="milestone-time milestone-time5">2025</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
};
</script>

<style scoped>
.about-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  background-color: #f4f4f4;
}

.content-container {
  max-width: 1020px;
  padding: 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 40px;
}

.content-container h1 {
  margin-top: 0;
  color: #333;
}

.content-container p {
  line-height: 1.6;
  color: #666;
}

/* Download apps section styling */
.download-apps {
  margin-top: 20px;
  text-align: center;
}

.download-apps p {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.app-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.app-logo {
  width: 150px;
  transition: transform 0.3s ease;
}

.app-logo:hover {
  transform: scale(1.1);
}

/* Tạo hiệu ứng bắt mắt cho nút tìm cửa hàng */
.store-link {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #FF6347;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border-radius: 25px;
  text-decoration: none;
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 15px rgba(255, 99, 71, 0.4);
}

.store-link:hover {
  transform: translateY(-5px) scale(1.05);
  background-color: #FF4500;
  box-shadow: 0 6px 25px rgba(255, 69, 0, 0.6);
}

/* Timeline Styling */
.timeline-wrapper {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  padding: 30px 20px;
  width: 100%;
  max-width: 1000px;
  position: relative;
  overflow: hidden;
  background-image: radial-gradient(circle, #FFD700, #FF6347);
}

.timeline-container {
  position: relative;
  width: 100%;
  height: 800px;
}

/* Smooth curved timeline */
.timeline-container svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* Milestone styles */
.milestone {
  position: absolute;
  width: 28px;
  height: 28px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 4px 20px rgba(255, 99, 71, 0.5);
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  z-index: 2;
}

/* Hover effects */
.milestone:hover {
  transform: scale(1.5);
  background-color: #FF4500;
  box-shadow: 0 6px 30px rgba(255, 69, 0, 0.7);
}

/* Tooltip content */
.milestone-content {
  display: none;
  position: absolute;
  background-color: #fff;
  padding: 12px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  color: #333;
  width: 180px;
  z-index: 3;
  text-align: center;
}

.milestone:hover .milestone-content {
  display: block;
  opacity: 1;
  transition: opacity 0.3s ease;
}

/* Timeline date styling */
.milestone-time {
  position: absolute;
  font-size: 16px;
  font-weight: bold;
  color: #FFF;
}

/* Positioning milestones */
.milestone1 { top: 100px; left: 10%; }
.milestone2 { top: 220px; left: 60%; }
.milestone3 { top: 370px; left: 40%; }
.milestone4 { top: 480px; left: 85%; }
.milestone5 { top: 650px; left: 65%; }

/* Timeline dates positioning */
.milestone-time1 { top: 130px; left: 5%; }
.milestone-time2 { top: 190px; left: 55%; }
.milestone-time3 { top: 400px; left: 35%; }
.milestone-time4 { top: 460px; left: 80%; }
.milestone-time5 { top: 680px; left: 60%; }

/* Responsive Design */
@media (max-width: 768px) {
  .timeline-wrapper {
    width: 100%;
    height: auto;
  }
  .timeline-container {
    height: 600px;
  }
}

@media (max-width: 480px) {
  .timeline-container {
    height: 500px;
  }
  .milestone-time {
    font-size: 14px;
  }
}
</style>
