<template>
    <div class="form-container">
      <h2>TRA CỨU THẺ THÀNH VIÊN</h2>
      <div class="input-group">
        <input
          v-model="phone"
          placeholder="Nhập số điện thoại"
          @input="validatePhone"
          @keydown.enter="generateLink"
          :class="{ 'invalid': phoneError }"
        />
        <p v-if="phoneError" class="error">{{ phoneError }}</p>
      </div>

      <div class="input-group">
        <input
          v-model="licensePlate"
          placeholder="Nhập biển số xe"
          @input="validateLicensePlate"
          @keydown.enter="generateLink"
          :class="{ 'invalid': licensePlateError }"
          maxlength="11"
        />
        <p v-if="licensePlateError" class="error">{{ licensePlateError }}</p>
      </div>

      <button :disabled="!isFormValid" @click="generateLink" class="submit-btn">
        Tra cứu
      </button>
    </div>
</template>

<script>
import { ref, watch } from 'vue';
import { createTempLink } from '@/utils/utils';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const phone = ref('');
    const licensePlate = ref('');
    const phoneError = ref('');
    const licensePlateError = ref('');
    const router = useRouter();

    const validatePhone = () => {
      const phonePattern = /^((03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b)$/;
      phoneError.value = phonePattern.test(phone.value)
        ? ''
        : 'Số điện thoại không hợp lệ.';
    };

    const validateLicensePlate = () => {
      licensePlateError.value = licensePlate.value.length <= 11
        ? ''
        : 'Biển số xe tối đa 11 ký tự.';
    };

    const generateLink = () => {
      validatePhone();  // Kiểm tra tính hợp lệ của số điện thoại
      validateLicensePlate();  // Kiểm tra tính hợp lệ của biển số xe
      if (!phoneError.value && !licensePlateError.value) {
        const tempLink = createTempLink(phone.value, licensePlate.value);
        router.push(tempLink);
      }
    };

    const isFormValid = ref(false);
    watch([phone, licensePlate], () => {
      validatePhone();
      validateLicensePlate();
      isFormValid.value = !phoneError.value && !licensePlateError.value && phone.value && licensePlate.value;
    });

    return {
      phone,
      licensePlate,
      phoneError,
      licensePlateError,
      isFormValid,
      generateLink,
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form-container {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  transition: all 0.3s ease;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 28px;
  font-weight: bold;
  background: linear-gradient(253deg, #0d8d39, #c0da67);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  animation: colorChange-3a5d5944 5s ease infinite;
}

.input-group {
  position: relative;
  margin-bottom: 1.2rem;
}

input {
  width: 100%;
  padding: 12px 0px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  transition: all 0.3s;
}

input:focus {
  border-color: #0072ff;
  box-shadow: 0 0 5px rgba(0, 114, 255, 0.3);
  outline: none;
}

.invalid {
  border-color: #ff4d4d;
}

.error {
  font-size: 0.9rem;
  color: #ff4d4d;
  margin-top: 0.3rem;
}

.submit-btn {
  width: 100%;
  padding: 12px;
  background-color: #4CAF50;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #4CAF50;
}

.submit-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
