export const createTempLink = (phone, licensePlate) => {
  const createdAt = Math.floor(Date.now() / 1000); // Lấy thời gian hiện tại dưới dạng timestamp (giây)

  // Mã hóa thông tin nhạy cảm
  const encodedPhone = btoa(phone); // Mã hóa số điện thoại
  const encodedLicensePlate = btoa(licensePlate); // Mã hóa biển số xe
  const cAt = btoa(createdAt);

  return `/t-mem?k4NjczODQxMg=${encodeURIComponent(encodedPhone)}&adqdDWQFB=${encodeURIComponent(encodedLicensePlate)}&cASDQVvas=${encodeURIComponent(cAt)}`;
};
