<template>
  <div class="homepage">
    <section class="hero">
      <h1>HKOIL Co.Ltd</h1>
      <p>Giới Thiệu Công ty</p>
    </section>
    <section class="content">
      <div class="profile">
        <img src="../assets/logo.png" alt="Ông Harry Tran" class="profile-img" />
        <div class="profile-info">
          <h2>HKOIL Co.Ltd</h2>
          <p class="title">HKOIL</p>
          <button @click="toggleContent" class="toggle-btn">{{ isContentVisible ? 'Ẩn Nội Dung' : 'Hiện Nội Dung' }}</button>
          <div v-show="isContentVisible" class="profile-text">
            <p>Đầu tiên, công ty cần gửi lời cảm ơn đến quý khách khách đã và đang đồng hành cùng mình trong suốt thời gian qua.</p>
            <p>Công ty TNHH TM & XD Hoàng Khải (Tên rút gọn: HKOIL) hoạt động trong lĩnh vực xăng dầu, công nghệ, vận tải và thương mại với mong muốn được phục vụ khách hàng tốt nhất.</p>
            <p>Với đội ngũ nhân viên, chuyên viên hàng đầu trong ngành Xăng dầu và Công nghệ, chúng tôi là doanh nghiệp tiên phong trong ứng dụng công nghệ vào ngành nghề truyền thống xăng dầu với mục tiêu đem lại trải nghiệm tốt nhất cho khách hàng, đối tác và nhà cung cấp.</p>
            <p>Với mục tiêu đó, HKOIL sẽ trở thành nơi hội tụ trí tuệ và kết nối trí thức toàn cầu, khơi thông dòng chảy nhiên liệu tới những vùng xa nhất của tổ quốc, tạo cơ hội cho mọi người dân được sử dụng nhiên liệu sạch, chuẩn, dịch vụ tiện ích với giá hợp lý để tạo tiền đề cho phát triển kinh tế bền vững của Giấc mơ Việt Nam – mang lại giá trị phụng sự cho mọi người dân Việt Nam</p>
            <p><strong>Sứ mệnh</strong><br>Tiên phong đổi mới, nâng tầm chất lượng dịch vụ ngành xăng dầu cho mỗi cá nhân, doanh nghiệp và tổ chức phát triển bền vững và bứt phá thành công.</p>
            <p>Tại HKOIL, chúng tôi đã xây dựng được một nền tảng thông tin và tri thức, công cụ hỗ trợ, dịch vụ chuyên biệt và danh mục sản phẩm đa dạng cùng với đội ngũ chuyên viên tư vấn có kinh nghiệm để giúp khách hàng kiến tạo Nhu cầu phù hợp một cách bền vững.</p>
            <p>Chúng tôi luôn tâm niệm mình đang làm nghề “bác sỹ xăng dầu” để giúp khách hàng giải quyết những khó khăn vướng mắc theo những đặc trưng riêng. Do vậy, mỗi con người cần hành động theo tiêu chí “Hiểu và Đồng hành” để thiết kế toàn bộ hành trình trải nghiệm nhằm giúp khách hàng tiếp cận như một kỹ năng sống thiết yếu, có được môi trường cung ứng an toàn, la bàn đầu tư cho từng bước đi trên hành trình đầu tư nhượng quyền cửa hàng xăng dầu và đủ sự lựa chọn phù hợp theo sự trưởng thành của khách hàng.</p>
            <p><strong>Tầm nhìn</strong><br>Trở thành doanh nghiệp có vị trí vững chắc trong Top 3 doanh nghiệp xăng dầu bán lẻ và nhượng quyền lớn nhất Việt Nam góp phần chuyển đổi ngành xăng dầu, nâng trải nghiệm dịch vụ, tiện ích; thúc đẩy mỗi người khai phá tiềm năng và bản lĩnh hành động biến những ước mơ thành hiện thực.</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  data() {
    return {
      isContentVisible: true
    };
  },
  methods: {
    toggleContent() {
      this.isContentVisible = !this.isContentVisible;
    }
  }
};
</script>

<style scoped>
.homepage {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  line-height: 1.6;
  padding: 10px 5px 5px 10px;
  background: linear-gradient(to right, #f8f9fa, #e9ecef);
  max-width: 1200px;
  margin: 0 auto;
}

.hero {
  background: linear-gradient(to right, #007bff, #6610f2);
  color: white;
  padding: 40px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  margin-bottom: 20px;
}

.hero h1 {
  font-size: 2.5em;
  margin: 0;
  font-weight: 700;
}

.hero p {
  font-size: 1.2em;
  margin: 10px 0;
  font-weight: 300;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.profile {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.profile-img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-right: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.profile-info {
  flex: 1;
  text-align: left;
}

.profile-info h2 {
  font-size: 2em;
  color: #333;
  margin-bottom: 10px;
  font-weight: 600;
}

.profile-info .title {
  font-size: 1.2em;
  color: #666;
  margin-bottom: 20px;
  font-weight: 400;
}

.profile-info .toggle-btn {
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1em;
  margin-bottom: 20px;
  transition: background 0.3s ease;
}

.profile-info .toggle-btn:hover {
  background: #0056b3;
}

.profile-text {
  margin-bottom: 15px;
  font-size: 1em;
  color: #555;
}

@media (max-width: 768px) {
  .hero {
    padding: 30px;
  }

  .hero h1 {
    font-size: 2em;
  }

  .hero p {
    font-size: 1em;
  }

  .profile {
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }

  .profile-img {
    width: 120px;
    height: 120px;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .profile-info {
    text-align: center;
  }

  .profile-info h2 {
    font-size: 1.5em;
  }

  .profile-info .title {
    font-size: 1em;
  }

  .profile-info .toggle-btn {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .hero {
    padding: 20px;
  }

  .hero h1 {
    font-size: 1.5em;
  }

  .hero p {
    font-size: 0.9em;
  }

  .profile {
    padding: 10px;
  }

  .profile-img {
    width: 100px;
    height: 100px;
  }

  .profile-info h2 {
    font-size: 1.2em;
  }

  .profile-info .title {
    font-size: 0.9em;
  }

  .profile-info .toggle-btn {
    font-size: 0.8em;
  }

  .profile-text {
    font-size: 0.9em;
  }
}
</style>
