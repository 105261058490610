// src/services/authService.js

import api from '@/api/axios'; // Import API đã cấu hình
import { useAuthStore } from '@/stores/authStore'; // Import auth store
import { useRouter } from 'vue-router'; // Import router

export const useAuthService = () => {
  const authStore = useAuthStore(); // Lấy instance của authStore
  const router = useRouter(); // Tạo instance của router

  const logout = async () => {
    try {
      await api.post('/auth/logout');
      authStore.clearTokens();
      authStore.isAuthenticated = false;
      router.push('/'); // Điều hướng tới trang chủ sau khi đăng xuất
    } catch (error) {
      console.error('Logout error:', error);
      authStore.clearTokens();
      authStore.isAuthenticated = false;
      router.push('/dang-nhap'); // Điều hướng tới trang đăng nhập nếu lỗi
    }
  };

  return {
    logout,
  };
};
