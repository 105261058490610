<template>
  <div class="system-list">
    <section class="hero">
      <h1>Danh Sách Hệ Thống</h1>
      <p>Khám phá các hệ thống công nghệ của chúng tôi</p>
    </section>
    <section class="content">
      <div class="system-grid">
        <div v-for="system in paginatedSystems" :key="system.id" class="system-box">
          <a :href="system.link" target="_blank">
            <img :src="system.image ? system.image : '../assets/logo.png'" alt="system.name" />
            <p>{{ system.name }}</p>
          </a>
        </div>
      </div>
      <div class="pagination">
        <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'SystemList',
  props: {
    systems: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 9 // 3 hệ thống mỗi hàng, 3 hàng
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.systems.length / this.itemsPerPage);
    },
    paginatedSystems() {
      if (!this.systems || this.systems.length === 0) {
        return [];
      }
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.systems.slice(start, end);
    }
  },
  methods: {
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    }
  }
};
</script>

<style scoped>
.system-list {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  line-height: 1.6;
  padding: 20px;
  background: linear-gradient(to right, #f8f9fa, #e9ecef);
  max-width: 1200px; /* Đặt kích thước tối đa cho trang giống home.vue */
  margin: 0 auto; /* Trung tâm hóa trang */
}

.hero {
  background: linear-gradient(to right, #007bff, #6610f2);
  color: white;
  padding: 40px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  margin-bottom: 20px; /* Thêm khoảng cách dưới hero */
}

.hero h1 {
  font-size: 2.5em;
  margin: 0;
  font-weight: 700;
}

.hero p {
  font-size: 1.2em;
  margin: 10px 0;
  font-weight: 300;
}

.content {
  margin-top: 20px;
}

.system-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.system-box {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px; /* Điều chỉnh kích thước padding */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.system-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.system-box img {
  width: 100%; /* Đặt chiều rộng của ảnh bằng 100% */
  max-width: 150px; /* Giới hạn chiều rộng tối đa của ảnh */
  height: auto;
  border-radius: 8px;
  margin-bottom: 15px; /* Tăng khoảng cách dưới ảnh */
}

.system-box p {
  font-size: 1.2em; /* Điều chỉnh kích thước chữ */
  margin-top: 10px;
}

.pagination {
  margin-top: 20px;
  text-align: center;
}

.pagination button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination button:disabled {
  background-color: #bbb;
}

.pagination span {
  margin: 0 10px;
}

/* Responsive design */
@media (max-width: 768px) {
  .hero {
    padding: 20px;
  }
  
  .hero h1 {
    font-size: 2em;
  }
  
  .hero p {
    font-size: 1em;
  }
  
  .system-box {
    padding: 15px;
  }
  
  .system-box img {
    max-width: 120px;
  }
  
  .pagination {
    font-size: 0.9em;
  }
}
</style>
