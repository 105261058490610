<template>
  <div class="register-container">
    <h2>Đăng ký tài khoản</h2>
    <form @submit.prevent="register">
      <div class="form-group">
        <label for="username">Username</label>
        <input
          type="text"
          id="username"
          v-model="userData.username"
          @blur="validateUsername"
          required
        />
        <span v-if="errors.username">{{ errors.username }}</span>
      </div>
      <div class="form-group">
        <label for="email">Email</label>
        <input
          type="email"
          id="email"
          v-model="userData.email"
          @blur="validateEmail"
          required
        />
        <span v-if="errors.email">{{ errors.email }}</span>
      </div>
      <div class="form-group">
        <label for="password">Mật khẩu</label>
        <input
          type="password"
          id="password"
          v-model="userData.password"
          @blur="validatePassword"
          required
        />
        <span v-if="errors.password">{{ errors.password }}</span>
      </div>
      <div class="form-group">
        <label for="confirmPassword">Xác nhận mật khẩu</label>
        <input
          type="password"
          id="confirmPassword"
          v-model="confirmPassword"
          @blur="validateConfirmPassword"
          required
        />
        <span v-if="errors.confirmPassword">{{ errors.confirmPassword }}</span>
      </div>
      <button type="submit" class="submit-button">Đăng ký</button>
      <a href="#" class="clear-link" @click.prevent="clearForm">Reset Form</a>
    </form>
    <div v-if="responseMessage" class="response-message">{{ responseMessage }}</div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';
import { useGasStore } from '@/stores/gasStore';

const userData = ref({
  username: '',
  email: '',
  password: ''
});
const confirmPassword = ref('');
const errors = ref({});
const responseMessage = ref('');
const userStore = useGasStore();
const aUrl = userStore.authURL  + 'auth/register';

// Hàm kiểm tra ký tự tiếng Việt có dấu
const containsVietnameseDiacritics = (text) => {
  const vietnamesePattern = /[àáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđ]/i;
  return vietnamesePattern.test(text);
};

// Validate Username (tối thiểu 4 ký tự và không chứa ký tự có dấu)
const validateUsername = () => {
  if (userData.value.username.length < 4) {
    errors.value.username = 'Username phải có ít nhất 4 ký tự';
  } else if (containsVietnameseDiacritics(userData.value.username)) {
    errors.value.username = 'Username không được chứa ký tự có dấu';
  } else {
    errors.value.username = '';
  }
};

// Validate Email (theo định dạng hợp lệ và không chứa ký tự có dấu)
const validateEmail = () => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailPattern.test(userData.value.email)) {
    errors.value.email = 'Email không hợp lệ';
  } else if (containsVietnameseDiacritics(userData.value.email)) {
    errors.value.email = 'Email không được chứa ký tự có dấu';
  } else {
    errors.value.email = '';
  }
};

// Validate Password (tối thiểu 8 ký tự, bao gồm ký tự đặc biệt, không có dấu)
const validatePassword = () => {
  const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  if (!passwordPattern.test(userData.value.password)) {
    errors.value.password = 'Mật khẩu phải có ít nhất 8 ký tự, bao gồm ký tự đặc biệt và số';
  } else if (containsVietnameseDiacritics(userData.value.password)) {
    errors.value.password = 'Mật khẩu không được chứa ký tự có dấu';
  } else {
    errors.value.password = '';
  }
};

// Validate Confirm Password (khớp với mật khẩu)
const validateConfirmPassword = () => {
  if (userData.value.password !== confirmPassword.value) {
    errors.value.confirmPassword = 'Xác nhận mật khẩu không khớp';
  } else {
    errors.value.confirmPassword = '';
  }
};

// Hàm Clear để xóa dữ liệu nhập
const clearForm = () => {
  userData.value = {
    username: '',
    email: '',
    password: ''
  };
  confirmPassword.value = '';
  errors.value = {};
  responseMessage.value = '';
};

// Submit Form (Gọi API đăng ký)
const register = async () => {
  validateUsername();
  validateEmail();
  validatePassword();
  validateConfirmPassword();

  if (Object.values(errors.value).every((err) => err === '')) {
    try {
      const response = await axios.post(aUrl, {
        username: userData.value.username,
        password: userData.value.password,
        email: userData.value.email
      });
      if (response.status === 201) {
        responseMessage.value = 'Đăng ký thành công!';
      } else {
        responseMessage.value = response.data.message || 'Đã xảy ra lỗi khi đăng ký';
      }
    } catch (error) {
      responseMessage.value = error.response?.data?.message || 'Đã xảy ra lỗi khi đăng ký';
    }
  }
};
</script>

<style scoped>
.register-container {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

span {
  color: red;
  font-size: 12px;
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #45a049;
}

.clear-link {
  display: block;
  margin-top: 10px;
  text-align: center;
  color: #007BFF;
  cursor: pointer;
}

.clear-link:hover {
  text-decoration: underline;
}

.response-message {
  margin-top: 20px;
  text-align: center;
  color: red;
}
</style>
