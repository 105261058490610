<template>
  <div>
    <h2>BIỂU ĐỒ LỊCH SỬ GIÁ XĂNG DẦU</h2>
    <Line :data="chartData" :options="chartOptions" v-if="chartData.datasets.length > 0" />
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { Line } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, PointElement, LinearScale, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns'; // Đảm bảo bạn đã cài đặt adapter
import { format, parse } from 'date-fns'; // Thêm thư viện date-fns để định dạng ngày

// Đăng ký các thành phần Chart.js
ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, LinearScale, TimeScale);

export default {
  components: {
    Line,
  },
  props: {
    gasData: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const chartData = ref({
      labels: [], // Danh sách nhãn cho trục x (ngày)
      datasets: [], // Danh sách datasets cho từng cặp vùng và sản phẩm
    });

    const chartOptions = {
      responsive: true,
      plugins: {
        legend: {
          display: true,
        },
      },
      scales: {
        x: {
          type: 'time', // Thiết lập trục x là kiểu thời gian
          time: {
            tooltipFormat: 'dd-MM-yyyy HH:mm:ss', // Định dạng tooltip cho ngày
            displayFormats: {
              hour: 'dd-MM-yyyy HH:mm:ss', // Định dạng hiển thị cho trục x
              day: 'dd-MM-yyyy', // Định dạng hiển thị cho ngày
            },
          },
          title: {
            display: true,
            text: 'Ngày',
          },
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Giá',
          },
        },
      },
    };

    // Hàm cập nhật dữ liệu biểu đồ
    const updateChartData = () => {
      const groupedData = {};

      // Bước 1: Nhóm dữ liệu theo (vùng, sản phẩm)
      props.gasData.forEach(item => {
        const date = item['Ngày bắt đầu hiệu lực'];
        const region = item['Vùng'];
        const product = item['Sản phẩm'];
        const price = item['Giá'];

        // Chuyển đổi định dạng ngày
        const parsedDate = parse(date, 'dd-MM-yyyy HH:mm:ss', new Date()); // Đọc từ định dạng
        const formattedDate = format(parsedDate, 'yyyy-MM-dd HH:mm:ss'); // Định dạng lại cho biểu đồ

        // Tạo key cho cặp (vùng, sản phẩm)
        const key = `${region}-${product}`;

        // Khởi tạo dữ liệu cho key nếu chưa tồn tại
        if (!groupedData[key]) {
          groupedData[key] = {
            label: `${region} - ${product}`,
            data: [],
            borderColor: getRandomColor(), // Tạo màu ngẫu nhiên cho mỗi cặp
            fill: false,
          };
        }

        // Bước 2: Thêm giá vào dữ liệu của cặp (vùng, sản phẩm)
        groupedData[key].data.push({ x: formattedDate, y: price }); // Sử dụng định dạng đã chỉnh sửa
      });

      // Chuyển đổi groupedData thành dạng datasets
      chartData.value.datasets = Object.values(groupedData); // Lấy dữ liệu cho từng cặp

      // Lấy danh sách nhãn cho trục x từ tất cả các điểm dữ liệu
      const dates = new Set();
      chartData.value.datasets.forEach(dataset => {
        dataset.data.forEach(dataPoint => {
          dates.add(dataPoint.x); // Lấy ngày theo định dạng YYYY-MM-DD HH:mm:ss
        });
      });

      chartData.value.labels = Array.from(dates).sort(); // Chuyển đổi Set thành mảng và sắp xếp
    };

    watch(() => props.gasData, updateChartData, { immediate: true });

    // Hàm tạo màu ngẫu nhiên
    function getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }

    return {
      chartData,
      chartOptions,
    };
  },
};
</script>

<style scoped>
/* Thêm kiểu cho biểu đồ nếu cần */
</style>
