<template>
  <div class="timeline">
    <div
      v-for="(event, index) in events"
      :key="index"
      :class="['container', index % 2 === 0 ? 'left' : 'right']"
    >
      <div class="content">
        <h2>{{ event.quarter }}</h2>
        <p>{{ event.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimeLine',
  data() {
    return {
      events: [
        { quarter: "2021", description: "Ông Ngô Kiên Thuận và Bà Nguyễn Thị Kiều Trang thành lập cty" },
        { quarter: "2022", description: "THƯƠNG NHÂN PHÂN PHỐI. Chính thức được cấp 29/03/2022" },
        { quarter: "2023", description: "Triển khai dịch vụ 5*" },
        { quarter: "2024", description: "Triển khai nhượng quyền HKOIL" },
        { quarter: "2025", description: "Ứng dụng AI/ML và mở rộng CRM đa kênh." }
      ]
    };
  }
};
</script>

<style scoped>
body {
  font-family: 'Arial', sans-serif;
  background-color: #f0f0f5;
  margin: 0;
  padding: 20px;
}

h1 {
  text-align: center;
  color: #FFD700;
  margin-bottom: 50px;
  font-size: 2.5rem;
  text-transform: uppercase;
}

.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* Đường timeline chính là đường cong */
.timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 6px;
  height: 100%;
  background-image: radial-gradient(circle, #FFD700, #000);
  background-size: 15px 100px;
  border-radius: 6px;
  z-index: 1;
  animation: animateLine 4s linear infinite;
}

.container {
  padding: 20px 40px;
  position: relative;
  width: 50%;
  box-sizing: border-box;
}

.container.left {
  left: 0;
  text-align: right;
}

.container.right {
  left: 50%;
}

.container::before {
  content: '';
  position: absolute;
  top: 20px;
  border-style: solid;
}

.container.left::before {
  left: 100%;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #FFD700;
}

.container.right::before {
  right: 100%;
  border-width: 10px 10px 10px 0;
  border-color: transparent #FFD700 transparent transparent;
}

/* Nội dung với viền, shadow và hiệu ứng hover */
.content {
  padding: 15px;
  background-color: #fff;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.4s ease-in-out, background-color 0.3s ease, box-shadow 0.3s ease;
  border: 2px solid #FFD700;
}

.container.left .content {
  transform-origin: right;
}

.container.right .content {
  transform-origin: left;
}

/* Hiệu ứng hover làm nổi bật */
.container.left .content:hover,
.container.right .content:hover {
  background-color: #fffae6;
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

h2 {
  color: #FFD700;
  margin: 0 0 10px;
  font-size: 1.6rem;
}

p {
  margin: 0;
  color: #333;
  font-size: 1rem;
}

/* Animation của đường timeline */
@keyframes animateLine {
  0% { background-position: 0 0; }
  100% { background-position: 0 100%; }
}

/* Hiệu ứng responsive */
@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }

  .container.left,
  .container.right {
    left: 0;
    text-align: left;
  }

  .container.left::before,
  .container.right::before {
    left: 30px;
    border-width: 10px 10px 10px 0;
    border-color: transparent #FFD700 transparent transparent;
  }

  .timeline::before {
    left: 30px;
  }
}
</style>
